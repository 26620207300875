import React from 'react';
import style from './styles.module.scss';

const EngageCard = ({ image, text, mobileText }) => (
  <div className={style.engageCardWrapper}>
    <div className={style.engageCardSection1}>
      <div className={style.imageWrapper}>
        <img className={style.image} src={image} alt="" />
      </div>
    </div>
    <div>
      <p className={style.text}>
        {text}
      </p>
      <p className={style.mobileText}>
        {mobileText}
      </p>
    </div>
  </div>

);

export default EngageCard;
