/* eslint-disable react/jsx-filename-extension */
import React, { useEffect, useState } from 'react';
import { isString } from 'lodash';
import { useNavigate } from '@reach/router';
import faqdata from '../../mockdata/faqcontent';

import Layout from '../../components/layout';

import SEO from '../../components/seo';
import { ProcessSection, FeaturesCard } from '../../components/brands';
import { Accordian } from '../../components/faq';

import BannerSection from '../../components/banner';

import care from '../../../static/patrons/svg/care.png';
import returns from '../../../static/patrons/svg/returns.png';
import rewards from '../../../static/patrons/svg/rewards.svg';
import explore from '../../../static/patrons/svg/Explore.svg';
import gift from '../../../static/patrons/svg/gift.svg';
import strong from '../../../static/patrons/svg/strong.png';
import monitor from '../../../static/patrons/svg/monitor.png';
import risk from '../../../static/patrons/svg/risk.svg';
// import SeamlessExp from '../../../static/patrons/svg/seamlessExp.svg';
// import robust from '../../../static/patrons/svg/robust.svg';

import apply from '../../../static/brands/svg/apply.svg';
import documentation from '../../../static/brands/svg/documentation.svg';

import welcomeKits from '../../../static/patrons/benefits/welcome-kit.svg';
import events from '../../../static/patrons/benefits/exclusive-events.svg';
import memorabilia from '../../../static/patrons/benefits/brand-memorabilia.svg';
import masterclasses from '../../../static/patrons/benefits/curated-masterclass.svg';
import specialDiscounts from '../../../static/patrons/benefits/special-discount.svg';

// import BannerImage from '../../../static/brands/png/banner-image.png';
// import groupStock from '../../../static/brands/svg/Group.svg';
// import deerStock from '../../../static/brands/svg/Deer.svg';

import EngageCard from '../../components/patrons/engage-card/engageCard';

import style from '../styles/patrons.module.scss';
import { Carousal } from '../../components/landing';
// import { TestimonialCard } from '../../components/landing';

import patronBanner from '../../../static/banners/patron.svg';
import { videoList } from '../../mockdata/video';
import Styles from '../styles/landingPage.module.scss';

import faqstyle from '../styles/faq.module.scss';
import SearchIcon from '../../../static/klub-usp/svg/Search.svg';
import { openURL, saveURLParamToSession } from '../../utils';
import TestimonialCard from '../../components/testimonialCard';
import Slider from '../../components/slider';

const features = [
  {
    icon: care,
    title: 'Brands curated with care',
    content:
      'Invest in your favorites across fashion, lifestyle, food & beverages & more'
  },
  {
    icon: returns,
    title: 'Attractive returns and rewards',
    content:
      'Competitive risk-adjusted returns with exclusive access to brand rewards'
  },
  {
    icon: rewards,
    title: 'Portfolio diversification',
    content:
      'Minimize risk and maximize returns with an alternative investment class'
  },
  {
    icon: strong,
    title: 'Strong risk framework',
    content:
      'Robust financial assessment in an 8-step underwriting process with legal documents vetted by experts'
  },
  {
    icon: monitor,
    title: 'Seamless investing and monitoring',
    content:
      'Easily discover and evaluate brands to invest in, and monitor your returns via our platform'
  },
  // {
  //   icon: SeamlessExp,
  //   title: 'Seamless experience',
  //   content: 'Easily discover, evaluate and compare brands to invest in via our platform'
  // }
  {
    icon: risk,
    title: 'Operational risk mitigation ',
    content:
      'Brand revenues flow through Klub’s innovative collection frameworks'
  }
  // {
  //   icon: robust,
  //   title: 'Robust financial assessment ',
  //   content: 'All Klub partner brands go through an 8-step rigorous underwriting process  '
  // }
];

const leftProcesses = [
  {
    icon: apply,
    title: 'Become a Klub patron',
    content: 'Tell us what kind of brands, sectors or returns you like'
  },
  {
    icon: documentation,
    title: 'Make your investment',
    content: 'Sign paperwork prepared by our legal experts and invest'
  }
];

const rightProcesses = [
  {
    icon: explore,
    title: 'Explore brands',
    content: 'Access real-time, curated deals at your fingertips'
  },
  {
    icon: gift,
    title: 'Enjoy returns and rewards',
    content:
      'Track your investment performance on the go and access exclusive rewards from brands'
  }
];

const processes = [
  {
    icon: apply,
    title: 'Become a Klub patron',
    content: 'Tell us what kind of brands, sectors or returns you like'
  },
  {
    icon: explore,
    title: 'Explore brands',
    content: 'Access real-time, curated deals at your fingertips'
  },
  {
    icon: documentation,
    title: 'Make your investment',
    content: 'Sign paperwork prepared by our legal experts and invest'
  },
  {
    icon: gift,
    title: 'Enjoy returns and rewards',
    content:
      'Track your investment performance and access exclusive rewards from brands'
  }
];

const engageSectionContent = [
  {
    text: 'Welcome kits',
    image: welcomeKits,
    mobileText: 'Welcome kits'
  },
  {
    text: 'Exclusive events',
    image: events,
    mobileText: 'Exclusive events'
  },
  {
    text: 'Branded memorabilia and merchandise',
    image: memorabilia,
    mobileText: 'Branded memorabilia'
  },
  {
    text: 'Special discounts',
    image: specialDiscounts,
    mobileText: 'Special discounts'
  },
  {
    text: 'Curated masterclasses',
    image: masterclasses,
    mobileText: 'Curated masterclasses'
  }
];

const PatronsPage = () => {
  const navigate = useNavigate();
  const [selectedQuestionId, setSelectedQuestionId] = useState(null);
  const [searchWord, setSearchWord] = useState('');
  const [searchResults, setSearchResults] = useState(faqdata.faqinvestors);
  const [prevSearchWord, setPrevSearchWord] = useState('');

  useEffect(() => {
    const hashValue = window
      && window.location
      && window.location.hash
      && window.location.hash.slice(1);
    if (hashValue) {
      const section = window && window.document && window.document.getElementById(hashValue);
      const headerOffset = 30;
      const elementPosition = section && section.getBoundingClientRect().top + window.scrollY;
      const offsetPosition = elementPosition - headerOffset;
      window.scrollTo({
        top: offsetPosition
      });
    }
  }, []);

  const handleChangeInput = (event) => {
    const { value } = event.target;
    if (searchWord !== value) {
      setSearchWord(value.trim());
      if (value === '') {
        onSearchClick();
      }
    }
  };

  const handlePlatformRedirect = () => {
    openURL('patron-app_signup');
  };

  const handleLoginRedirect = () => {
    openURL('patron-app_login');
  };

  const onSearchClick = (e) => {
    if (e) e.preventDefault();
    setPrevSearchWord(searchWord);
    const results = [];
    // eslint-disable-next-line consistent-return
    faqdata.faqinvestors.forEach((item) => {
      if (
        isString(item.question)
        && item.question.toUpperCase().includes(searchWord.toUpperCase())
      ) {
        return results.push(item);
      }
    });
    setSearchResults(results);
  };

  const handleFaqScroll = () => {
    navigate('/patrons#faq');
    const faq = document.getElementById('faq');
    if (faq) {
      faq.scrollIntoView({ behavior: 'smooth' });
    }
  };

  useEffect(() => {
    saveURLParamToSession();
  }, []);

  return (
    <Layout>
      <SEO
        title="Grow the brands you love"
        description="Invest in your favorite consumer brands and get attractive monthly returns, brand perks and exclusive rewards."
      />
      <BannerSection
        image={patronBanner}
        heading="Grow the brands you love, "
        subHeading="and your capital"
        description="Attractive returns for investors who are"
        subDescription="a step ahead of the rest"
        buttons={[
          <button
            type="button"
            onClick={handlePlatformRedirect}
            className="btn btnklub1"
          >
            Sign Up
          </button>,
          <button
            type="button"
            onClick={handleLoginRedirect}
            className="btn btnklub5 ml3"
          >
            Login
          </button>
        ]}
      />

      <div className={style.patronSection1}>
        <div className="container">
          <div className={style.section}>
            <h3 className={style.sectionHeader}>INVEST IN YOUR PASSION</h3>
            <h1 className={style.contentMob}>
              Be a part of an exclusive invite-
              <br />
              only community
            </h1>
            <h5 className={style.contentWeb}>
              Be a part of an exclusive invite-only
              <br />
              community
            </h5>
          </div>
        </div>
        <div className={style.carouselWrapper}>
          <Carousal name="patronFeatures" margin={true} autoScroll={false}>
            {features.map((item, index) => (
              <FeaturesCard
                // eslint-disable-next-line react/no-array-index-key
                key={`${index}`}
                content={item.content}
                title={item.title}
                icon={item.icon}
                style={{
                  marginRight: '24px'
                }}
              />
            ))}
          </Carousal>
        </div>
        <div className={`${style.processButtonWrapper} container`}>
          <button
            type="button"
            onClick={handlePlatformRedirect}
            className="btn btnklub3"
          >
            Start investing
          </button>
          <button type="button" onClick={handleFaqScroll} className="btn btnklub4 ml3">
            Learn more
          </button>
        </div>
      </div>

      <div className={style.patronSection2}>
        <div className="container">
          <div className={style.section}>
            <h3 className={style.sectionHeader}>ENGAGE WITH BRANDS YOU LOVE</h3>
            <h5 className={style.content}>
              Enjoy attractive brand perks
            </h5>
          </div>
          <div className={style.engageCardContainer}>
            {engageSectionContent
              && engageSectionContent.map((content) => (
                <div className={style.engageCard}>
                  <EngageCard
                    image={content.image}
                    text={content.text}
                    mobileText={content.mobileText}
                  />
                </div>
              ))}
          </div>
        </div>
      </div>
      {/* Video Testimonial */}
      <div className={`${Styles.sectionWrapper} ${Styles.homeSection2}`}>
        <div>
          <div className={`${Styles.sectionContentBox} container`}>
            <h3>TESTIMONIALS</h3>
            <h5>Hear from our patrons</h5>
          </div>
          <div className={`${Styles.videocardSection} singleVideoCardSlider container`}>
            <Slider
              settings={{
                slidesToShow:
                  typeof window !== 'undefined' && window?.innerWidth > 768
                    ? 2
                    : 1
              }}
              name="video-testimonial-about"
            >
              {videoList.home.filter((item) => !item.isBrand).map((item) => (
                <TestimonialCard
                  key={item.id}
                  dp={item.dp}
                  img={item.imgSrc}
                  head={item.head}
                  name={item.name}
                  profile={item.profile}
                  url={item.url}
                  isHomepage={true}
                  webp={item.webpSrc}
                  isBrand={false}
                  dpWebp={item.dpWebp}
                />
              ))}
            </Slider>
          </div>
        </div>
      </div>
      <div className={style.patronSection2}>
        <div className="container">
          <div className={style.section}>
            <h3 className={style.sectionHeader}>WE MAKE INVESTING EASY</h3>
            <h5 className={style.contentWeb}>
              Explore alternative investments
              <br />
              with a hassle-free investing experience
            </h5>
            <h5 className={style.contentMob}>
              Explore alternative investments
              <br />
              with a hassle-free
              <br />
              investing experience
            </h5>
          </div>
          <ProcessSection
            name="patrons"
            leftProcesses={leftProcesses}
            rightProcesses={rightProcesses}
            processes={processes}
          />
          <div className={style.processButtonWrapper}>
            <button
              type="button"
              onClick={handlePlatformRedirect}
              className="btn btnklub3"
            >
              Explore brands
            </button>
          </div>
        </div>
      </div>
      <div className={faqstyle.faqSection3} id="faq">
        <div className={faqstyle.section31Wrapper}>
          <h5 className={faqstyle.sectionHeadingStyle}>
            Frequently asked questions
          </h5>
          <form className={faqstyle.searchBarWrapper} onSubmit={onSearchClick}>
            <input
              type="text"
              aria-label="Search"
              placeholder="Ask a question"
              onChange={handleChangeInput}
              className={faqstyle.inputFieldSearch}
            />
            <button
              type="submit"
              className={`btn btnklub3 ${faqstyle.searchButton}`}
              onClick={onSearchClick}
            >
              <img src={SearchIcon} loading="lazy" alt="search" />
              <div className={faqstyle.searchBtnText}>Search</div>
            </button>
          </form>
        </div>
        <div className="container">
          <div className={faqstyle.accordianSectionWrapper}>
            {searchResults.length > 0
              && searchResults.map((element) => (
                <Accordian
                  key={element.id}
                  id={element.id}
                  selectedId={selectedQuestionId}
                  title={element.question}
                  // eslint-disable-next-line react/no-children-prop
                  children={element.answer}
                  searchWord={searchWord === prevSearchWord ? searchWord : ''}
                  handleClick={setSelectedQuestionId}
                />
              ))}
          </div>
          <div className={faqstyle.section3Mail}>
            Can’t find your question here?
            <br />
            <span>
              Write to us at
              <a
                href="mailto:contact@klubworks.com"
                target="_blank"
                rel="noopener noreferrer"
                className={`tag ${faqstyle.linkStyle}`}
              >
                contact@klubworks.com
              </a>
            </span>
          </div>
        </div>
      </div>
      {/* Testimonials section */}
      {/* <section className={style.testimonialWrapper}>
        <div className="container">
          <h3>TESTIMONIALS</h3>
          <h1 className={style.webView}>
            What our patrons say
            <br />
            about us
          </h1>
          <h1 className={style.mobileView}>What our partners say about us</h1>
        </div>
        <div className={style.carouselWrapper}>
          <Carousal
            name="testimonials-patrons"
            margin={true}
            autoScroll={false}
          >
            {data.map((item, index) => (
              <TestimonialCard
                // eslint-disable-next-line react/no-array-index-key
                key={`${index}`}
                content={item.content}
                partnerImage={item.partnerImage}
                partnerName={item.partnerName}
                companyName={item.companyName}
              />
            ))}
          </Carousal>
        </div>

      </section> */}

      {/* <section className={style.patronSection4}>
        <div className="container">
          <div className={style.section}>
            <div className={style.applyReferSection}>
              <div className={style.applyCardWrapper}>
                <ApplyReferCard
                  icon={apply}
                  heading="Apply"
                  link="/patrons-contact"
                  content="Join Klub’s exclusive patron network! "
                  buttonText="Request an Invite"
                />
              </div>
              <div className={style.applyCardMiddleWrapper}>
                <ApplyReferCard
                  icon={groupStock}
                  heading="Refer a Patron"
                  content="Know someone who would love to join Klub’s investor network? "
                  buttonText="Share"
                />
              </div>
              <div className={style.applyCardWrapper}>
                <ApplyReferCard
                  icon={deerStock}
                  heading="Refer a Brand"
                  content="Know a brand that could benefit from the Klub network?"
                  buttonText="Refer Now"
                />
              </div>
            </div>
          </div>
        </div>
      </section> */}
    </Layout>
  );
};
export default PatronsPage;
